import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Normalize from 'react-normalize';
import SiteBgState from './context/site_bg/SiteBgState';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
	<>
		<Normalize />
		<SiteBgState>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</SiteBgState>
	</>,
	document.getElementById('root')
);
