import React from 'react';
import { Link } from 'react-router-dom';
import { FancyButton } from '../../styles/Buttons.style';

const WelcomeTextComp = () => {
	return (
		<>
			<div className='lead'>
				<p>
					<strong>Webes</strong>
				</p>
				<p>megoldásokat</p>
				<p>tervezünk</p>
			</div>
			<div className='sub'>
				<p>
					Örülünk annak, hogy nap mint nap azzal foglalkozhatunk amit igazán
					szeretünk, összekötjük a vállalkozásokat saját felhasználóikkal.
				</p>
			</div>
			<FancyButton>
				<Link to='/rolunk'>Kik is vagyunk?</Link>
			</FancyButton>
		</>
	);
};

export default WelcomeTextComp;
