import React, { useEffect, useContext } from 'react';
import {
	Title,
	Container,
	SimpleTitle,
	UnderlinedText,
	Spacer,
} from '../../styles/Site.style';
import PerPattern from '../../../assets/images/perpattern.png';
import AboutUsTitle from './AboutUsTitle';
import AboutUsDropdown from './AboutUsDropdown';
import Coffee from '../../global/Coffee';
//import AboutUsTeam from './team/AboutUsTeam';
import SiteBgContext from '../../../context/site_bg/siteBgContext';
import BG from '../../../assets/images/bg/bg-bemutatkozas-top.png';

const AboutUs = () => {
	const siteBgContext = useContext(SiteBgContext);
	useEffect(() => {
		window.scrollTo(0, 0);
		siteBgContext.setActiveBg(BG);
		//eslint-disable-next-line
	}, []);
	return (
		<Container>
			<SimpleTitle>
				<AboutUsTitle />
			</SimpleTitle>

			<Spacer height='80px' mobile_none></Spacer>
			<Title>
				<UnderlinedText perpattern={PerPattern}>Így dolgozunk</UnderlinedText>
				&nbsp;
				<div>
					<p>erre számíthatsz a</p>
					<p>fejlesztés során</p>
				</div>
			</Title>
			<AboutUsDropdown />

			<Spacer height='80px'></Spacer>
			{/*<Title>
				<UnderlinedText perpattern={PerPattern}>Kik is vagyunk?</UnderlinedText>
				&nbsp;
				<div>
					<p>mi leszünk a</p>
					<p>segítségedre</p>
				</div>
			</Title>
			<AboutUsTeam >*/}
			<Coffee />
		</Container>
	);
};

export default AboutUs;
